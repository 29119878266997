import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IpromiseService {
  constructor(private http: HttpClient,private router: Router) {}
  API = 'http://www.udumalaipettai.co.in/apifiles/ipromise_wb/';
  ContactFrm(name: any, email: any, phone_number: any, msg_subject: any, message: any,) {
    var url = this.API + 'create_new_whatsapp.php';
    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone_number", phone_number);
    formData.append("msg_subject", msg_subject);
    formData.append("message", message);
    return this.http.post(url, formData)
  }

  ContactFrm2(name: any, phone_number: any,) {
    var url = this.API + 'create_new_whatsapp.php';
    var formData = new FormData();
    formData.append("name", name);
    formData.append("phone_number", phone_number);
 
    return this.http.post(url, formData)
  }


}
