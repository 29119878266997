import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const toggleButton = document.getElementById('toggleButton');
    const popupContainer = document.getElementById('popupContainer');
    const closeButton = document.getElementById('closeButton');

    toggleButton.addEventListener('click', () => {
      popupContainer.style.display = 'block'; 
              toggleButton.style.display = 'none'; 
  });
  closeButton.addEventListener('click', () => {
      popupContainer.style.display = 'none';
      toggleButton.style.display = 'block'; 
  });
  
  
  }

}
