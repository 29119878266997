import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { IpromiseService } from 'src/app/ipromise.service';

@Component({
    selector: 'app-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    myInterval: any;

    constructor(private manas: IpromiseService, private http: HttpClient, private router: Router,) { }
    ContactFrm: FormGroup | any;
    ngOnInit() {
        this.ContactFrm = new FormGroup({
            name: new FormControl('', Validators.required),
          //  email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
            phone_number: new FormControl('', [Validators.required, Validators.minLength(10)]),
           // msg_subject: new FormControl('',),
          //  message: new FormControl('',),
          })


        this.myInterval = setInterval(() => {
            this.commingSoonTime();
        }, 0);
    }

    commingSoonTime = () => {
        const endTimeParse = (Date.parse('March 20, 2025 17:00:00 PDT')) / 1000;
        const now = new Date();
        const nowParse = (Date.parse(now.toString()) / 1000);
        const timeLeft = endTimeParse - nowParse;
        const days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < 10) { hours = 0 + hours; }
        if (minutes < 10) { minutes = 0 + minutes; }
        if (seconds < 10) { seconds = 0 + seconds; }
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

    feedback() {

        this.ContactFrm.markAllAsTouched();
        if (this.ContactFrm.get('name').invalid) {
          Swal.fire({
            title: 'Enter Name',
            icon: 'warning',
          })
        }
        else if (this.ContactFrm.get('phone_number').invalid) {
          Swal.fire({
            title: 'Enter Mobile Number',
            icon: 'warning',
          })
        }
    
    
        //------------------------------------------------------
        if (this.ContactFrm.valid) {
          this.manas.ContactFrm2(this.ContactFrm.get('name').value,
           // this.ContactFrm.get('email').value,
            this.ContactFrm.get('phone_number').value,
          //  this.ContactFrm.get('msg_subject').value,
          //  this.ContactFrm.get('message').value,
    
          ).subscribe((data: any) => {
            this.ContactFrm.reset();
            if (data.status == 'Success') {
              Swal.fire({
                title: 'Our Team Contact Soon!',
                icon: 'success',
              })
             
            }
            else if (data.status == 'error') {
              Swal.fire({
                title: 'Contact Number Already Entered!',
                icon: 'warning',
              })
            }
          })
        }
      }
}